import {Component, OnInit} from '@angular/core';
import {Question, QuestionService} from "./question.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public questions: Question[];
  public error: any;

  constructor(private readonly questionService: QuestionService) {
  }

  ngOnInit(): void {
    this.questionService.getQuestions()
      .subscribe(questions => this.questions = questions, error => {
        console.error(error);
        this.error = error.message;
      });
  }
}
