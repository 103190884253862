<div *ngIf="activeQuestion" [@changeQuestion]="activeQuestion.id">
  <h1>{{activeQuestion.title}}</h1>
  <p>{{activeQuestion.text}}</p>
  <img *ngIf="activeQuestion.image" src="./assets/question-images/{{activeQuestion.image}}" alt="">
  <div class="answers">
    <button *ngFor="let answer of activeQuestion.answers" (click)="selectAnswer(answer)">
      {{answer.caption}}
    </button>
  </div>
</div>
<ng-container *ngIf="result">
  <div>
    <img src="./assets/result-images/{{result}}" alt="{{result}}">
  </div>
  <div>
    <button (click)="startOver()">Start over</button>
  </div>
</ng-container>
