import {Component, Input, OnInit} from '@angular/core';
import {ACTION_PREFIX_QUESTION, Answer, Question} from '../question.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  animations: [
    trigger('changeQuestion', [
      transition((fromState: string, toState: string) => {
        // Animate only when changing between questions, not when going to end-result
        return toState !== 'void';
      }, [
        style({
          opacity: 0,
        }),
        animate(250)
      ])
    ]),
  ],
})
export class QuestionnaireComponent implements OnInit {
  @Input('questions')
  private readonly questions: Question[];

  public activeQuestion: Question;
  public result: any;

  ngOnInit(): void {
    this.selectQuestion();
  }

  selectQuestion(id?: any) {
    this.activeQuestion = this.questions
      .find(question => (!id && question.firstQuestion) || question.id === id);
    if (!this.activeQuestion) {
      console.error('Could not find next question' + (id ? ' with id ' + id : ''));
    }
  }

  selectAnswer(answer: Answer) {
    this.activeQuestion = null;

    if (!answer.action) {
      console.error('Missing action');
      this.result = "Answer not implemented";
    } else if (answer.action.startsWith(ACTION_PREFIX_QUESTION)) {
      this.selectQuestion(answer.action.substr(ACTION_PREFIX_QUESTION.length));
    } else {
      this.result = answer.action;
    }
  }

  startOver() {
    this.result = null;
    this.selectQuestion();
  }
}
