<div id="container">
  <div class="container-item" *ngIf="error">
    {{error}}
  </div>
  <div class="container-item" *ngIf="!error && !questions">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
  <div class="container-item" *ngIf="!error && questions">
    <app-questionnaire [questions]="questions"></app-questionnaire>
  </div>
</div>
