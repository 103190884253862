import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {map, tap} from "rxjs/operators";

import * as ajv from 'ajv';

interface QuestionsResponse {
  questions: Question[];
}

export interface Question {
  id: any;
  title: string;
  text: string;
  image?: string;
  answers: Answer[];
  firstQuestion?: boolean;
}

export interface Answer {
  caption: string;
  action: string;
}

export const ACTION_PREFIX_QUESTION = 'question:';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  constructor(private readonly http: HttpClient) {
  }

  public getQuestions(): Observable<Question[]> {
    return forkJoin({
      schema: this.http.get<QuestionsResponse>('./assets/questions.schema.json'),
      questions: this.http.get<QuestionsResponse>('./assets/questions.json')
    })
      .pipe(
        tap((responses: { schema: any, questions: QuestionsResponse }) => this.validateQuestions(responses.schema, responses.questions)),
        map((responses: { schema: any, questions: QuestionsResponse }) => responses.questions),
        map((response: any) => response.questions)
      );
  }

  private validateQuestions(schema: any, questions: QuestionsResponse) {
    const validate = this.compileSchema(schema);
    if (!validate) {
      return;
    }

    try {
      if (!validate(questions)) {
        console.error('JSON not valid', JSON.stringify(validate.errors, null, "\t"));
        return;
      }
    } catch (error) {
      console.error('JSON is invalid', error);
      return;
    }

    this.checkImages(questions);
  }

  private compileSchema(schema) {
    try {
      return ajv().compile(schema);
    } catch (error) {
      console.error('Schema is invalid', error);
    }
  }

  private checkImages(questions: QuestionsResponse) {
    [
      ...questions.questions
        .map(question => question.image)
        .filter(image => !!image)
        .map(image => 'question-images/' + image),
      ...questions.questions
        .map(question => question.answers)
        .reduce((a, b) => [...a, ...b], [])
        .map(answer => answer.action)
        .filter(action => !action.startsWith(ACTION_PREFIX_QUESTION))
        .map(image => 'result-images/' + image)
    ].forEach(image => this.http.head('assets/' + image)
      .subscribe(() => {
      }, error => console.error('Image not found: ' + error.message)));
  }
}
